"use strict";
exports.__esModule = true;
function binaryOperation(operator, left, right) {
    switch (operator) {
        case '+':
            return left + right;
        case '-':
            return left - right;
        case '/':
            return left / right;
        case '%':
            return left % right;
        case '*':
            return left * right;
        case '**':
            return Math.pow(left, right);
        case '&':
            return left & right;
        case '|':
            return left | right;
        case '>>':
            return left >> right;
        case '>>>':
            return left >>> right;
        case '<<':
            return left << right;
        case '^':
            return left ^ right;
        case '==':
            return left == right;
        case '===':
            return left === right;
        case '!=':
            return left != right;
        case '!==':
            return left !== right;
        case 'in':
            return left in right;
        case 'instanceof':
            return left instanceof right;
        case '>':
            return left > right;
        case '<':
            return left < right;
        case '>=':
            return left >= right;
        case '<=':
            return left <= right;
    }
}
exports["default"] = binaryOperation;
